<template>
  <div>
    <ul class="Notifications__List" v-if="values.length">
      <li class="Notifications__List--Item" v-for="n in values" :key="n.id">
        <!-- Se quita el replace para no alterar el valor original; 
             se espera que el valor en n.type ya sea EL_... o WL_... -->
        <notification-item :text="n.text.translated" :date="n.creationDate" :type="n.type" :seen="n.seen"
          @open-notification="openNewScreen($event, n.activityId, n.communityId, n.commentId, n.commentPage)"></notification-item>
      </li>
      <li class="Notifications__List--Footer" @click="openAllNotifications">
        <h5 role="button">{{ $t('notification_see_previous') }}</h5>
      </li>
    </ul>
    <ul class="Notifications__List" v-if="!values.length">
      <li class="Notifications__List--Item">
        <notification-item :text="$t('notification_up_to_date')" type="none"></notification-item>
      </li>
      <li class="Notifications__List--Footer" @click="openAllNotifications">
        <h5 role="button">{{ $t('notification_see_previous') }}</h5>
      </li>
    </ul>
  </div>
</template>

<script>
import NotificationItem from '@/components/NotificationItem.vue';
import {
  EL_MEDAL,
  WL_MEDAL,
  EL_ANSWER,
  WL_ANSWER,
  EL_ACT_REMINDER,
  WL_ACT_REMINDER,
  EL_ACT_LAUNCHED,
  WL_ACT_LAUNCHED,
  EL_COM_LAUNCHED,
  WL_COM_LAUNCHED,
  EL_MENTION,
  WL_MENTION,
  EL_MOD_NEW_ANSWER_TO_COMMENT,
  WL_NEW_ANSWER_TO_COMMENT,
  WL_MOD_NEW_ANSWER_TO_COMMENT,
  EL_MOD_MENTIONED_IN_COMMENT,
  WL_MOD_MENTIONED_IN_COMMENT,
  EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
  WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED
} from '~/constants/constants.type'

export default {
  name: 'NotificationsPanel',
  components: {
    NotificationItem
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: null
    }
  },
  computed: {
    mainClass() {
      return this.show ? 'NotificationsPanel' : 'NotificationsPanel hide'
    },
    values() {
      return this.data ? this.data.filter((d, i) => i < 5) : [];
    }
  },
  data() {
    return {
      // Se guardan todas las variantes para usarlas en las comparaciones
      EL_MEDAL,
      WL_MEDAL,
      EL_ANSWER,
      WL_ANSWER,
      EL_ACT_REMINDER,
      WL_ACT_REMINDER,
      EL_ACT_LAUNCHED,
      WL_ACT_LAUNCHED,
      EL_COM_LAUNCHED,
      WL_COM_LAUNCHED,
      EL_MENTION,
      WL_MENTION,
      EL_MOD_NEW_ANSWER_TO_COMMENT,
      WL_NEW_ANSWER_TO_COMMENT,
      WL_MOD_NEW_ANSWER_TO_COMMENT,
      EL_MOD_MENTIONED_IN_COMMENT,
      WL_MOD_MENTIONED_IN_COMMENT,
      EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
    WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED
    }
  },
  methods: {
    navigateTo(name, arg) {
      this.$emit('close-panel');
      const params = { origin: this.$route.params.origin };
      if (arg) params.id = arg;
      this.$router.push({ name, params });
    },
    openAllNotifications() {
      this.$emit('clear-all-notifications');
      this.navigateTo('notifications');
    },
    async scrollToComment(commentId) {
      console.log(commentId);
      await this.$nextTick();
      const commentElement = document.querySelector(`.comment_${commentId}`);
      if (commentElement) {
        commentElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        commentElement.style.backgroundColor = 'rgba(255, 215, 0, 0.3)';
        commentElement.style.padding = '5px';
        commentElement.style.borderRadius = '5px';
        commentElement.style.transition = 'all 0.5s ease';
        setTimeout(() => {
          commentElement.style.backgroundColor = 'transparent';
          commentElement.style.padding = '0';
          commentElement.style.borderRadius = '0';
        }, 5000);
      }
    },
    openNewScreen(event, act, cmty, cmnt, cmntP) {
      console.log("openNewScreen act:", act);
      console.log("openNewScreen cmty:", cmty);
      console.log("openNewScreen cmnt:", cmnt);
      console.log("openNewScreen cmntP:", cmntP);
      console.log("openNewScreen event:", event);

      const { type } = event;
      const currentRoute = this.$route;
      const isCurrentRoute = (name, params) => {
        return currentRoute.name === name &&
          (!params || Object.keys(params).every(key => currentRoute.params[key] === params[key]));
      };

       if (type === this.EL_COM_LAUNCHED || type === this.WL_COM_LAUNCHED) {
        if (!isCurrentRoute('welcome')) {
          this.navigateTo('welcome');
        }
      }
      // Para actividad lanzada o recordatorio
      else if (
        type === this.EL_ACT_LAUNCHED || type === this.WL_ACT_LAUNCHED ||
        type === this.EL_ACT_REMINDER || type === this.WL_ACT_REMINDER || type === this.EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED || type ===
        this.WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED
      ) {
        if (!isCurrentRoute('activity.info', { id: act })) {
          this.navigateTo('activity.info', act);
        }
      }
      // Para notificaciones de mención, respuesta y respuestas o menciones moderadas
      else if (
        type === this.EL_MENTION || type === this.WL_MENTION ||
        type === this.EL_ANSWER || type === this.WL_ANSWER ||
        type === this.EL_MOD_NEW_ANSWER_TO_COMMENT ||
        type === this.WL_NEW_ANSWER_TO_COMMENT ||
        type === this.WL_MOD_NEW_ANSWER_TO_COMMENT ||
        type === this.EL_MOD_MENTIONED_IN_COMMENT ||
        type === this.WL_MOD_MENTIONED_IN_COMMENT ||
        type === this.EL_MEDAL || type === this.WL_MEDAL
      ) {
        this.$router.push({
          name: 'activity.info',
          params: { notificationCommentId: cmnt, notificationCommentPage: cmntP, id: act }
        }).then(() => {
          this.scrollToComment(cmnt);
        });
      }
      else {
        console.warn('Tipo de notificación no reconocido:', type);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Notifications__List {
  list-style-type: none;

  &--Item {
    padding: 1rem;
    border-bottom: 1px solid lightgray;
    max-width: 500px;
  }

  &--Footer {
    background: #fefefe;
    color: #999;
    padding: 0.8rem 0;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #eaeaea;
    box-shadow: inset 0px 10px 17px -10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}
</style>
