<template lang="html">
  <div class="Chat">
    <div class="Chat__Messages" ref="messagesElement">
      <div class="NoMessages" v-if="messagesStream.length == 0">
        <img class="NoMessages__Icon" src="../../assets/img/no-messages-chat.png">
        <div class="NoMessages__Message">
          <p v-if="isModerator">{{$t('chat_info')}}</p>
          <p v-if="!isModerator">{{$t('chat_info_user')}}</p>
        </div>
      </div>
      <div class="Chat__Message" v-for="message in messagesStream" v-if="messagesStream.length > 0 && isModerator" :class="{ 'Chat__Message--Current': !message.isModerator }">
        <div class="Message__Content" v-if="!message.image && !message.video && !message.file">{{message.message}}</div>
        <div class="Message__Media" v-if="message.image || message.video || message.file">
          <img :src="message.image" v-if="message.image" @click="$emit('enlarge-pic', message.image)">
          <video :src="message.video" v-if="message.video" controls></video>
          <a :href="message.file" v-if="message.file"><i class="fas fa-file-download"></i>{{$t('comment_download_subject')}}</a>
        </div>
        <div class="Message__Time">{{ message.creationDate | formatMessageDate }}</div>
      </div>
      <div class="Chat__Message" v-for="message in messagesStream" v-if="messagesStream.length > 0 && !isModerator" :class="{ 'Chat__Message--Current': message.isModerator }">
        <div class="Message__Content" v-if="!message.image && !message.video && !message.file">{{message.message}}</div>
        <div class="Message__Media" v-if="message.image || message.video || message.file">
          <img :src="message.image" v-if="message.image" @click="$emit('enlarge-pic', message.image)">
          <video :src="message.video" v-if="message.video" controls></video>
          <a :href="message.file" v-if="message.file"><i class="fas fa-file-download"></i>{{$t('comment_download_subject')}}</a>
        </div>
        <div class="Message__Time">{{ message.creationDate | formatMessageDate }}</div>
      </div>
    </div>

    <div class="Chat__AddMedia" v-if="photoUploadUrl" :style="isModerator && 'width:70%'">
      <div class="Chat__AddMedia--Media" v-if="!showVideoUploader && !showFileUploader">
        <span class="Chat__AddMedia--icon" @click="showImageUploader = !showImageUploader">
          <i class="fa fa-image"></i>
        </span>
        <transition name="fade">
          <Dropzone
            v-if="showImageUploader"
            :uploadUrl="photoUploadUrl"
            :uploadHeaders="fileUploadHeaders"
            :uploadText="$t('comment_upload_image')"
            acceptedFiles="image/jpg,image/jpeg,image/png"
            :maxFiles="1"
            @file-uploaded="handleAddImage"
            @file-removed="handleRemoveImage">
          </Dropzone>
        </transition>
      </div>
      <div class="Chat__AddMedia--Media" v-if="!showVideoUploader && !showImageUploader">
        <span class="Chat__AddMedia--icon" @click="showFileUploader = !showFileUploader">
          <i class="fa fa-file"></i>
        </span>
        <transition name="fade">
          <Dropzone
            v-if="showFileUploader"
            :uploadUrl="photoUploadUrl"
            :uploadHeaders="fileUploadHeaders"
            :uploadText="$t('comment_upload_image')"
            :maxFiles="1"
            @file-uploaded="handleAddImage"
            @file-removed="handleRemoveImage">
          </Dropzone>
        </transition>
      </div>
      <div class="Chat__AddMedia--Media" v-if="!showFileUploader && !showImageUploader">
        <span class="Chat__AddMedia--icon" @click="showVideoUploader = !showVideoUploader">
          <i class="fa fa-video"></i>
        </span>
        <transition name="fade">
          <Dropzone
            v-if="showVideoUploader"
            :uploadUrl="videoUploadUrl"
            :uploadHeaders="fileUploadHeaders"
            :uploadText="$t('comment_upload_image')"
            acceptedFiles="video/*"
            :maxFiles="1"
            @file-uploaded="handleAddImage"
            @file-removed="handleRemoveImage">
          </Dropzone>
        </transition>
      </div>
    </div>

    <div class="Chat__Controls">
      <textarea
        class="Chat__Input"
        :placeholder="image ? $t('chat_image_message_info') : $t('chat_write_text')"
        required
        v-model="inputMessage"
        v-on:keyup.enter.exact="handleSendMessage($event)"
        rows="1" :style="chatInputStyle"
        ref="chatInputElement"
        :disabled="image"
      ></textarea>
      <w-button color="accent" class='Chat__SubmitButton' v-on:click="handleSendMessage($event)" :disabled="disableButton || disableSubmit">{{$t('action_send')}}</w-button>
    </div>

  </div>
</template>

<script>
import Button from '../Button';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import IconButton from '../IconButton';
import Dropzone from '../Dropzone';
import ApiService from '../../services/api.service';

export default {
  name: 'Chat',

  props: {
    messages: {
      type: Array,
      default: () => [],
    },

    isModerator: {
      type: Boolean,
      default: false,
    },

    photoUploadUrl: {
      type: String,
      default: ''
    },
    videoUploadUrl: {
      type: String,
      default: ''
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    IconButton,
    Dropzone,
    'w-button': Button
  },

  computed: {
    ...mapGetters([
      'isObservator'
    ]),

    messagesStream: function () {
      this.adjustMessagesScroll()
      return this.messages
    },

    disableButton ( ) {
      return !this.inputMessage.length
        ? !this.image
        : false;
    }
  },

  data() {
    return {
      inputMessage: '',
      chatInputStyle: {},
      showImageUploader:false,
      showFileUploader: false,
      showVideoUploader: false,
      fileUploadHeaders: ApiService.getConfig().headers,
      image: null,
    }
  },

  filters: {
    formatMessageDate: (value) => {
      return moment(value).format('DD/MM/YYYY HH:mm')
    },
  },

  async mounted() {
    this.adjustMessagesScroll()
  },

  methods:{
    handleSendMessage: function (e) {
      e.preventDefault()
      let data = {
        message:this.inputMessage.trim()
      }
      if(this.showImageUploader)  {
        data.image = this.image
        data.message = 'image'
      }
      else if(this.showFileUploader) {
        data.file = this.image
        data.message = 'file'
      }
      else if(this.showVideoUploader){
        data.video = this.image
        data.message = 'video'
      }
      this.$emit('send-message', data)
      this.showFileUploader = false
      this.showImageUploader = false
      this.showVideoUploader = false
      this.inputMessage = ''
      this.adjustMessagesScroll()
    },

    adjustMessagesScroll: function () {
      if (this.$refs.messagesElement != undefined) {
        setTimeout(() => {
          this.$refs.messagesElement.scrollTop = this.$refs.messagesElement.scrollHeight
        }, 500)
      }
    },

    adjustInputMessageHeight: function () {
      this.chatInputStyle = {
        height: `${this.$refs.chatInputElement.scrollHeight}px`
      }
    },

    handleAddImage ( file, response ) {
      if ( !this.image ) this.image = response.path;
    },

    handleRemoveImage: function (file) {
      if ( this.image.match( file.name ) ) this.image = null;
    },
  },
}
</script>

<style lang="scss">
.Chat {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background: #fff;
  box-shadow: 0 0 30px #efefef;
  border-radius: .5em;

  &__Messages {
    padding: 1em;
    background-color: #fff;
    border: 1px solid #eee;
    height: 400px;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 6px;
      cursor: pointer;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  &__Message {
    background-color: #f9f9f9;
    color: #bfbfbf;
    padding: .5em;
    margin-bottom: 1px;
    max-width: 80%;
    min-width: 40%;
    float: right;
    clear: both;
    position: relative;
    overflow-wrap: anywhere;

    &::before {
      width: 0;
      height: 0;
      border-top: 1px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #EEE;
      content: ' ';
      position: absolute;
      right: -10px;
    }

    .Message__Content {
      float: left;
      clear: left;
      white-space: pre-line;
      min-width: 50%;
      margin-bottom: 3px;

      &--image {
        width: 100%;
      }
    }
    .Message__Media{
      width: 100%;
      i{
        margin-right: 5px;
      }
      a{
        color:#3e3e3e;
      }
      img,video{
        width: 100%;
        border: 1px solid #D3D3D3;
        cursor: pointer;
      }
    }


    .Message__Time {
      font-size: 10px;
      opacity: 0.8;
      text-align: right;
      width: 100%;
      float: right;
      clear: both;
    }

    &--Current {
      background-color: #4d1f99;
      float: left;

      &::before {
        right: auto;
        border-left: none;
        border-right: 10px solid #33106A;
        left: -10px;
      }
    }
  }

  &__AddMedia {
    display:flex;
    &--icon {
      background-color: #F2F2F2;
      margin: 5px 0;
      height: 30px;
      width: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #DFDFDF;
      }
    }

  }


  .Chat__Message--Current + .Chat__Message:not(.Chat__Message--Current),
  .Chat__Message:not(.Chat__Message--Current) + .Chat__Message--Current {
    margin-top: 15px;
  }

  &__Input {
    padding: 1em;
    border: 1px solid #eee;
    background-color: #f9f9f9;
    width: 100%;
    max-height: 150px;
    overflow-y: auto !important;
    margin-top: .5em;
    resize: none;
    field-sizing: content;
    max-height: 150px;
    &::-webkit-scrollbar-track {
        background-color: #e6e6e6;
      }
    
      &::-webkit-scrollbar {
        background-color: #8134ff;
        width: 3px;
      }
    
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--primary-color);
      }

    &:disabled {
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }
  }

  &__SubmitButton {
    font-size: .9em;
    padding: .4em .8em;
    border-radius: 0 !important;
    float: right;
    min-width: auto;
  }

  .NoMessages {
    text-align: center;
    margin-top: 30%;

    &__Icon {
      margin-bottom: 1em;
      max-width: 120px;
    }

    &__Message {
      color: #AAA;
      text-align: center;
    }
  }
}


// Transitions
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
// Modal
.Modal {
  &__Container {
    width          : 70vw !important;
  }

  &__Header {
    text-align     : center !important;
  }

  &__Content {
    display        : flex !important;
    justify-content: center !important;
  }

  &--image {
    max-width      : 70vw;
    max-height     : 70vh;
  }

  &--close-button {
    cursor: default;
    color: var( --primary-color );
  }

  &__Footer {
    width: 100%;
    max-width: 80%;
  }
}
</style>
