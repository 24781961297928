<template>
  <div
    :style="!seen && 'border-left: 2px solid red'"
    class="NotificationItem"
    @click="handleClick"
  >
    <div class="NotificationItem__Icon" v-if="type !== 'none'">
      <!-- Se comparan ambas variantes para cada tipo de notificación -->
      <img v-if="type === EL_ANSWER || type === WL_ANSWER || type === EL_MOD_NEW_ANSWER_TO_COMMENT || type === WL_MOD_NEW_ANSWER_TO_COMMENT || type === WL_MOD_MENTIONED_IN_COMMENT" src="@/assets/img/opinionIcon.png">
      <img v-if="type === EL_ACT_REMINDER || type === WL_ACT_REMINDER" src="@/assets/img/blogIcon.png">
      <img v-if="type === EL_ACT_LAUNCHED || type === WL_ACT_LAUNCHED" src="@/assets/img/blogIcon.png">
      <img v-if="type === EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED || type === WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED" src="@/assets/img/blogIcon.png">
      <img v-if="type === EL_COM_LAUNCHED || type === WL_COM_LAUNCHED" src="@/assets/img/pollIcon.png">
      <Trophy v-if="type === EL_MEDAL || type === WL_MEDAL" type="gold" style="width: 50px"></Trophy>
    </div>
    <div class="NotificationItem__Text">
      <span class="title" v-text="text"></span>
      <small class="date" v-text="formatDate"></small>
    </div>
  </div>
</template>

<script>
import Trophy from '@/components/Trophy.vue';
import {
  EL_MEDAL,
  WL_MEDAL,
  EL_ANSWER,
  WL_ANSWER,
  EL_ACT_REMINDER,
  WL_ACT_REMINDER,
  EL_ACT_LAUNCHED,
  WL_ACT_LAUNCHED,
  EL_COM_LAUNCHED,
  WL_COM_LAUNCHED,
  EL_MOD_NEW_ANSWER_TO_COMMENT,
  WL_MOD_NEW_ANSWER_TO_COMMENT,
  EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
WL_MOD_MENTIONED_IN_COMMENT
} from '~/constants/constants.type'

export default {
  name: 'NotificationItem',
  props: {
    text: {
      type: String,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    seen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // Se guardan las variantes que se usarán en las comparaciones para renderizar los íconos
      EL_MEDAL,
  WL_MEDAL,
  EL_ANSWER,
  WL_ANSWER,
  EL_ACT_REMINDER,
  WL_ACT_REMINDER,
  EL_ACT_LAUNCHED,
  WL_ACT_LAUNCHED,
  EL_COM_LAUNCHED,
  WL_COM_LAUNCHED,
  EL_MOD_NEW_ANSWER_TO_COMMENT,
  WL_MOD_NEW_ANSWER_TO_COMMENT,
  EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
  WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED,
  WL_MOD_MENTIONED_IN_COMMENT
    }
  },
  components: {
    Trophy
  },
  methods: {
    handleClick() {
      // Se emite el evento con el tipo tal como se recibe
      this.$emit('open-notification', { type: this.type });
    }
  },
  computed: {
    formatDate() {
      return this.date ? new Date(this.date).toLocaleString('es-ES') : '';
    }
  }
}
</script>

<style scoped lang="scss">
.NotificationItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__Text {
    width: 100%;
    padding-left: 1rem;
    font-size: 0.8rem;
    transition: 200ms;
    &:hover {
      padding-left: 1.2rem;
      & > .title {
        color: #555;
      }
    }
    .title {
      font-weight: bold;
      color: #888;
      transition: 200ms;
    }
    .date {
      display: block;
      color: #bfbfbf;
      padding-top: 0.3rem;
      font-size: 0.8rem;
    }
  }
}
</style>
